import React, { useState } from "react"
import {
  Box,
  Grid,
  List,
  ListItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import useSendMessages from "../../../hooks/useSendMessages"

const Empty = () => <Typography>Нет рассылок</Typography>

const Loading = () => {
  return (
    <Skeleton>
      <Box sx={{ width: "80%", height: "150px" }} />
    </Skeleton>
  )
}

const SendMessage = ({ data }) => (
  <ListItem
    sx={{
      width: "100%",
      border: "1px #ccc solid",
      borderRadius: "5px",
      cursor: "pointer",
      background: data.status === "closed" ? "#ddd" : "inherit",
    }}
  >
    <Stack direction={"row"} gap={1} alignItems={"center"}>
      <Typography>{data.text}</Typography>
      <Typography>{data.created_at}</Typography>
    </Stack>
  </ListItem>
)

export default function SendMessages({ startDate, endDate, size }) {
  const { sendMessages } = useSendMessages(startDate, endDate)
  console.log(sendMessages)
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
        boxSizing: "border-box",
        backgroundColor: "#fff",
        borderRadius: "15px",
        padding: 2,
      }}
    >
      <Typography variant="h6">История рассылок</Typography>
      <List
        sx={{
          width: "100%",
          display: "flex",
          flexFlow: "column",
          gap: "5px",
        }}
      >
        {sendMessages ? (
          sendMessages.data.length ? (
            sendMessages.data.map((d) => <SendMessage key={d} data={d} />)
          ) : (
            <Empty />
          )
        ) : (
          <Loading />
        )}
      </List>
    </Box>
  )
}

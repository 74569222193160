import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackBarCustom } from "./useSnackbarCustom"

const getsendMessagesRequest = async (headers, showSnackbar, startDate, endDate,) => {
  const res = await fetch(process.env.REACT_APP_API_URL + "send_messages/newsletters/", {
    headers: headers,
    method: "GET",
  }).catch((e) => showSnackbar("Не удалось получить телефоны"))
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    return resData
  }
  showSnackbar("Не удалось выполнить запрос")
  return false
}

export default function useSendMessages(startDate, endDate) {
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackBarCustom()
  const [sendMessages, setSendMessages] = useState(false)

  useEffect(() => {
    const load = async () => {
      const headers = await getAuthHeader()
      const sendMessages = await getsendMessagesRequest(headers, showSnackbar,startDate, endDate)
      setSendMessages(sendMessages)
    }
    load()
  }, [])

  return { sendMessages }
}

import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackbarContext } from "../hocs/SnackbarProvider"


const getCustomerRequest = async (headers, showSnackbar, phone) => {
  let params = new URLSearchParams({
    phone: phone,
  })
  const res = await fetch(process.env.REACT_APP_API_URL + `customers/?` + params.toString(), {
    method: 'GET',
    headers: headers
  }).catch(e => {
    alert('Ошибка ' + e.name + ":" + e.message + "\n" + e.stack); 
  })
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
  }
  if (!res?.ok) return false
  return await res.json()
}

export default function useCustomer (phone){
  const [customer, setCustomer] = useState(false)
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackbarContext()

  useEffect(() => {
    setCustomer(false)
    const load = async () => {
      const headers = await getAuthHeader()
      const res = await getCustomerRequest(headers, showSnackbar, phone)
      setCustomer(res ? res : false)
    }
    load()
    setCustomer(false)
  }, [phone])
  return { customer }
}
import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import { TabContext, TabList } from "@mui/lab"
import CustomTabPanel from "../../../components/CustomTabPanel"
import useCalls from "../../../hooks/useCalls"
import dayjs from "dayjs"
import useOrders from "../../../hooks/useOrders"
import Orders from "../../Feedback/Orders"
import useFilters from "../../../hooks/useFilters"
import CallModal from "components/CallModal"

const valueOrNot = (value) => (value ? value : "-")

const CallRow = ({ call, setModalCall }) => {
  const size = "small"

  return (
    <TableRow
      sx={{ background: call.result?.result_type ? "#eee" : "inherit" }}
    >
      <TableCell
        size={size}
        align="center"
        sx={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          textWrap: "nowrap",
        }}
      >
        {valueOrNot(call.date)}
      </TableCell>
      <TableCell size={size} align="center">
        {call.phone}
      </TableCell>
      <TableCell size={size} align="center">
        {call.department ? valueOrNot(call.department.title) : "-"}
      </TableCell>
      <TableCell size={size} align="center">
        {call.result?.result_type ? call.result.result_type.title : "-"}
      </TableCell>
      <TableCell size={size} align="center">
        {call.result?.comment ? call.result.comment : "-"}
      </TableCell>
      <TableCell size={size} align="center">
        {call.result?.price ? call.result.price : "-"}
      </TableCell>
      <TableCell>
        <Button onClick={() => setModalCall(call)}>Детали</Button>
      </TableCell>
    </TableRow>
  )
}

const Calls = ({ phone }) => {
  const [startDate, setStartDate] = useState(dayjs(new Date()))
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  const [filters, setFilters] = useState({ phone: phone })
  const [modalCall, setModalCall] = useState(false)
  const { fields } = useFilters("selects/calls/", false)
  const { calls, setCalls } = useCalls(
    filters,
    false,
    startDate,
    endDate,
    0,
    100
  )
  const headers = [
    "Дата",
    "Номер",
    "Подразделение",
    "Результат",
    "Комментарий",
    "Сумма",
  ]
  if (!calls) {
    return <Typography>Загрузка</Typography>
  }

  return (
    <Table>
      <TableRow>
        {headers.map((h) => (
          <TableCell align="center" key={h}>
            <Typography variant="span">{h}</Typography>
          </TableCell>
        ))}
      </TableRow>
      <TableBody>
        {calls.results.map((c) => (
          <CallRow key={c.id} call={c} setModalCall={setModalCall} />
        ))}
      </TableBody>
      <CallModal
        onClose={() => setModalCall(false)}
        fields={fields}
        setCalls={setCalls}
        calls={calls}
        call={modalCall}
      />
    </Table>
  )
}

const ClientOrders = ({ phone }) => {
  const [fullTable, setFullTable] = useState(false)
  const [startDate, setStartDate] = useState(dayjs(new Date()))
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  const [filters, setFilters] = useState({ phone: phone })
  const { fields } = useFilters("selects/feedback/")
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(100)
  const { orders, setOrders } = useOrders(
    page,
    perPage,
    startDate,
    endDate,
    filters,
    fields
  )

  if (!orders) {
    return <Typography>Загрузка</Typography>
  }
  // return <></>
  return (
    <Orders
      orders={orders}
      setOrders={setOrders}
      page={page}
      setPage={setPage}
      perPage={perPage}
      setPerPage={setPerPage}
      fields={fields}
      filters={filters}
      setFilters={setFilters}
      fullTable={fullTable}
      setFullTable={setFullTable}
    />
  )
}

export default function ClientInfo({ phone }) {
  const [tab, setTab] = useState("0")
  const handleChange = (e, v) => setTab(v)

  return (
    <Box
      p={2}
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        borderRadius: "15px",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <TabContext value={tab}>
        <TabList
          sx={{ width: "100%", height: "50px", padding: 0 }}
          onChange={handleChange}
          allowScrollButtonsMobile
          aria-label="custome-tabs"
        >
          <Tab label="Звонки" value="0" />
          <Tab label="Заказы" value="1" />
        </TabList>
        <CustomTabPanel value={"0"}>
          <Calls phone={phone} />
        </CustomTabPanel>
        <CustomTabPanel value={"1"}>
          <ClientOrders phone={phone} />
        </CustomTabPanel>
      </TabContext>
    </Box>
  )
}

import React, { useEffect, useState } from "react"
import Header from "../../components/Header"
import dayjs from "dayjs"
import { Box } from "@mui/material"
import CallModal from "../../components/CallModal"
import useCalls from "../../hooks/useCalls"
import useFilters from "../../hooks/useFilters"
import CallsTable from "../../components/CallsTable"
import { useOutletContext, useSearchParams } from "react-router-dom"

export default function Operator() {
  const [searchParams, setSearchParams] = useSearchParams()

  const [startDate, setStartDate] = useState(
    searchParams.get("startDate")
      ? dayjs(searchParams.get("startDate"), "DD-MM-YYYY")
      : dayjs(new Date())
  )
  const [endDate, setEndDate] = useState(
    searchParams.get("endDate")
      ? dayjs(searchParams.get("endDate"), "DD-MM-YYYY")
      : dayjs(new Date())
  )
  const [modalCall, setModalCall] = useState(false)
  const [page, setPage] = useState(Number(searchParams.get("page") || 0))
  const [perPage, setPerPage] = useState(10)
  const { fields, filters, setFilters } = useFilters("selects/calls/")
  const { calls, setCalls } = useCalls(
    filters,
    false,
    startDate,
    endDate,
    page,
    perPage
  )
  const pagePadding = useOutletContext()

  const updateValue = (setParam, paramName) => (newValue) => {
    setParam(newValue)
    setSearchParams((params) => {
      if (newValue instanceof dayjs) {
        newValue = newValue.format("DD-MM-YYYY")
      }
      params.set(paramName, newValue)
      return params
    })
  }

  return (
    <Box
      flexDirection={"column"}
      display={"flex"}
      sx={{
        gap: pagePadding,
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <CallModal
        calls={calls}
        setCalls={setCalls}
        fields={fields}
        onClose={() => setModalCall(false)}
        call={modalCall}
      />
      <Header
        startDate={startDate}
        setStartDate={updateValue(setStartDate, "startDate")}
        endDate={endDate}
        setEndDate={updateValue(setEndDate, "endDate")}
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
          boxSizing: "border-box",
          backgroundColor: "#fff",
          borderRadius: "15px",
        }}
      >
        <CallsTable
          filters={filters}
          setFilters={setFilters}
          calls={calls}
          setCalls={setCalls}
          page={page}
          setPage={updateValue(setPage, "page")}
          setModalCall={setModalCall}
          rows={perPage}
          setRows={setPerPage}
        />
      </Box>
    </Box>
  )
}

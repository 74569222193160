import React, { useEffect, useState } from "react"
import {
  Box,
  Grid,
  MenuItem,
  Select,
  Slider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"

export default function ClientFilter({
  LTV,
  setLTV,
  rating,
  setRating,
  sort,
  setSort,
}) {
  const theme = useTheme()
  const [ratingDelay, setRatingDelay] = useState(rating)
  const [ltvDelay, setLtvDelay] = useState(LTV)
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    const timeOutId = setTimeout(() => setRating(ratingDelay), 500)
    return () => clearTimeout(timeOutId)
  }, [ratingDelay])

  useEffect(() => {
    const timeOutId = setTimeout(() => setLTV(ltvDelay), 500)
    return () => clearTimeout(timeOutId)
  }, [ltvDelay])

  return (
    <Box
      p={isSmScreen ? 2 : 4}
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        backgroundColor: "#fff",
        borderRadius: "15px",
      }}
    >
      <Typography variant="h6">Фильтры</Typography>
      <Grid container>
        <Grid xs={12} md={6} lg={4} p={2}>
          <Stack direction={"column"} alignItems={"flex-start"}>
            <Typography sx={{ width: "100%" }}>{"Рейтинг"}</Typography>
            <Slider
              sx={{ width: "85%" }}
              value={ratingDelay}
              onChange={(e, v) => setRatingDelay(v)}
              min={0.0}
              max={5.0}
              step={0.1}
              valueLabelDisplay="auto"
              disableSwap
              marks={[
                { value: 0, label: "0 ⭐" },
                { value: 5, label: "5 ⭐" },
              ]}
            />
          </Stack>
        </Grid>
        {/* <Grid xs={12} md={6} lg={4} p={2} rowSpacing={1}>
          <Typography sx={{ width: "100%" }}>Дата регистрации</Typography>
          <DatePicker value={startDate} onChange={(v) => setStartDate(v)} />
          <DatePicker value={endDate} onChange={(v) => setEndDate(v)} />
        </Grid> */}
        <Grid xs={12} md={6} lg={4} p={2}>
          <Stack direction={"column"} alignItems={"center"}>
            <Typography sx={{ width: "100%" }}>{`LTV`}</Typography>
            <Slider
              sx={{ width: "85%" }}
              value={ltvDelay}
              onChange={(e, v) => setLtvDelay(v)}
              min={0.0}
              max={1_000_000}
              step={10000}
              valueLabelDisplay="auto"
              disableSwap
              marks={[
                { value: 0, label: "0 ₽" },
                { value: 1_000_000, label: "∞ ₽" },
              ]}
            />
          </Stack>
        </Grid>
      </Grid>
      <Stack direction={"row"} mt={1} alignItems={"center"}>
        <Typography mr={1}>Сортировать:</Typography>
        <Select
          size="small"
          value={sort}
          onChange={(e) => setSort(e.target.value)}
        >
          <MenuItem value={1}>Не выбрано</MenuItem>
          <MenuItem value={2}>{`По частоте (вверх)`}</MenuItem>
          <MenuItem value={3}>{`По частоте (вниз)`}</MenuItem>
        </Select>
      </Stack>
    </Box>
  )
}

import {
  Box,
  Button,
  Rating,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import React from "react"
import useCustomer from "../../../hooks/useCustomer"

const Loading = () => (
  <Box
    p={2}
    sx={{
      width: "100%",
      height: "100%",
      boxSizing: "border-box",
      borderRadius: "15px",
      boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
      overflow: "hidden",
    }}
  >
    <Skeleton>
      <Typography variant="h5">{""}</Typography>
    </Skeleton>
    <Skeleton>
      <Typography variant="h6">{""}</Typography>
    </Skeleton>
    <Skeleton>
      <Typography variant="span">{"LTV: 10 000"}</Typography>
    </Skeleton>
    <Skeleton>
      <Typography variant="span">{"Последний филиал: Фучика"}</Typography>
    </Skeleton>
  </Box>
)

export default function Client({ phone }) {
  const { customer } = useCustomer(phone)
  if (!customer) return <Loading />

  return (
    <Box
      p={2}
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        borderRadius: "15px",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <Stack direction={"column"} gap={1}>
        <Typography variant="h5">{`ФИО: ${customer?.name || "-"}`}</Typography>
        <Typography variant="h6">{`Телефон: ${phone}`}</Typography>
        <Typography variant="span">{`Email: ${
          customer?.email || "-"
        }`}</Typography>
        <Typography variant="span">{`Дата рождения: ${
          customer?.date_of_birth || "-"
        }`}</Typography>
        <Typography variant="span">{`LTV: ${
          customer?.lifetime_value || "-"
        } ₽`}</Typography>
        <Typography variant="span" alignItems={"center"}>
          {`Рейтинг: ${customer?.feedback_rate || "-"}`}
          {customer?.feedback_rate ? (
            <Rating
              sx={{ margin: 1 }}
              readOnly
              defaultValue={5.0}
              precision={customer.feedback_rate || 0.0}
            />
          ) : (
            <></>
          )}
        </Typography>
        <Stack
          gap={1}
          sx={{ width: "100%" }}
          direction={"column"}
          alignItems={"flex-end"}
          justifyContent={"center"}
        >
          <TextField
            sx={{ width: "100%" }}
            multiline
            // {...register("comment", {
            //   maxLength: {
            //     value: 1200,
            //     message: "Максимум 1200 символов",
            //   },
            //   value: call.result?.comment ? call.result.comment : undefined,
            // })}
            label={"Комментарий"}
            // error={errors?.comment}
            // helperText={errors?.comment?.message}
          ></TextField>
          <Button variant="contained">Сохранить</Button>
        </Stack>
      </Stack>
    </Box>
  )
}

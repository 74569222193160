import React from "react"
import Client from "./Client"
import { useOutletContext, useParams } from "react-router-dom"
import { Box } from "@mui/material"
import ClientInfo from "./ClientInfo"
import Header from "../../components/Header"

export default function ClientCard() {
  const { phone } = useParams()
  const pagePadding = useOutletContext()
  console.log(phone)

  return (
    <>
      <Box
        sx={{
          flexFlow: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          gap: pagePadding,
        }}
      >
        <Header />
        <Client phone={phone} />
        <ClientInfo phone={phone} />
      </Box>
    </>
  )
}

import React, { useEffect, useState } from "react"
import { SearchOutlined } from "@mui/icons-material"
import { InputAdornment, TextField } from "@mui/material"

export default function SearchByPhone({ filters, setFilters }) {
  const [query, setQuery] = useState(false)

  useEffect(() => {
    if (query !== false) {
      const timeOutId = setTimeout(
        () => setFilters({ ...filters, phone: query }),
        500
      )
      return () => clearTimeout(timeOutId)
    }
  }, [query])

  return (
    <TextField
      type={"number"}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined />
          </InputAdornment>
        ),
      }}
      onChange={(e) => setQuery(e.target.value)}
      size="small"
      defaultValue={""}
      label={"Номер"}
      variant={"outlined"}
    />
  )
}
